import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'nav',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/homePage.vue'),
      }, {
        path: '/types',
        name: 'types',
        component: () => import('../views/homeTypes.vue'),
      }, {
        path: '/store',
        name: 'store',
        component: () => import('../views/homeStore.vue'),
      }, {
        path: '/about',
        name: 'about',
        component: () => import('../views/homeAbout.vue'),
      }, {
        path: '/reservation',
        name: 'reservation',
        component: () => import('../views/homeReservation.vue'),
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/privacy.vue')
      },
      {
        path: '/securityNotice',
        name: 'securityNotice',
        component: () => import('../views/securityNotice.vue')
      }
    ]
  },
  {
    path: '/about1',
    name: 'about1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
